// Search
function afficheState(state) {
    if ((state == 'mediaDesktop') || (state == 'mediaTablet')) {
        $('.ddm__home_affiche').dropDownMenu({
            disabled:    true,
            classBtn:    '',
            independent: false
        });
    } else if ((state == 'mediaSmartphone')) {
        $('.ddm__home_affiche').dropDownMenu({
            disabled:    false,
            classBtn:    '',
            independent: false
        });
    }
}
