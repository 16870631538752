// Filters
function filtersState(state) {
    if (state == 'mediaDesktop' || state == 'mediaTablet') {
        $('.filters .ddm').dropDownMenu({
            disabled:    true,
            classBtn:    '',
            independent: true
        });
    } else if (state == 'mediaSmartphone') {
        $('.filters .ddm').dropDownMenu({
            disabled:    false,
            classBtn:    'button-2 button-2--no-text-in-mobile',
            independent: true
        });
    }
}
