// Menu responsive
function responsiveMenu() {
    var respMenu = $('.responsiveMenu'), //menu css class
        html = $('html'),
        body = $('body'),
        search = $('.search-box'),
        language = $('.pane-gtranslate-gtranslate'),
        responsiveMenuActiveClass = "responsiveMenuActive"; //css class to toggle site overlay

    if(respMenu.length > 0 && !responsiveMenu.inited) {
        responsiveMenu.inited = true;

        if($(".responsiveMenuOverlay").length == 0) {
            $('.page').append('<div class="responsiveMenuOverlay"></div>');
        }

        if($(".mainMenuBtn").length == 0) { // On vérifie que le bouton n'existe pas déjà
            $('.mob-nav').prepend('<button type="button" class="mainMenuBtn resetButton" aria-expanded="false" aria-controls="mainMenu"><img src="' + pathImgPictos + 'mainmenu-responsive.png" alt="Ouvrir le menu principal" /></button>');
        }

        if($(".closeResponsiveMenu").length == 0) { // On vérifie que le bouton n'existe pas déjà
            respMenu.prepend('<button type="button" class="closeResponsiveMenu"><img src="' + pathImgPictos + 'mainmenu-responsive.png" alt="' + l10n['openMenu'] + '" />menu</button><button type="button" class="closeResponsiveMenu closeResponsiveMenu-right"><img src="' + pathImgPictos + 'mainmenu-responsive.png" alt="' + l10n['openMenu'] + '" /></button>');
        }

    
        respMenu
        .append($('.main-menu').clone().removeAttr('id').attr('class', 'mobile-menu'))
        .append(search.clone())
        .append(language.clone());

        var mainMenu = $('.mob-nav .mobile-menu'),
            menuActions = $('.mainMenuBtn, .closeResponsiveMenu'),
            mainMenuBtn = $('.mainMenuBtn'),
            closeResponsiveMenu = $('.closeResponsiveMenu');

        function openResponsiveMenuFallback() {
            // Désactive tous les liens sous le menu
            $('a, input, button, select').attr('tabIndex', -1);
            $('a, input, button, select', respMenu).attr('tabIndex', 0);
            html.addClass(responsiveMenuActiveClass);
            body.addClass(responsiveMenuActiveClass);
            // Affiche le menu
            mainMenuBtn.attr('aria-expanded', 'true');
            // Changement d'état du alt de l'image du mainMenuBtn
            var replaceAltmainMenuBtn = mainMenuBtn.find('img').attr('alt').replace(l10n['open'], l10n['close']);
            mainMenuBtn.find('img').attr('alt', replaceAltmainMenuBtn);
            // Changement d'état du alt de l'image du closeResponsiveMenu
            var replaceAltmainMenuBtnOpen = closeResponsiveMenu.find('img').attr('alt').replace(l10n['open'], l10n['close']);
            closeResponsiveMenu.find('img').attr('alt', replaceAltmainMenuBtnOpen);
        }

        function closeResponsiveMenuFallback() {
            // Active tous les liens sous le menu
            $('a, input, button, select').attr('tabIndex', 0);
            // Renvoie le focus sur le btn d'ouverture du menu
            mainMenuBtn.focus();
            html.removeClass(responsiveMenuActiveClass);
            body.removeClass(responsiveMenuActiveClass);
            // Masque le menu
            mainMenuBtn.attr('aria-expanded', 'false');
            // Changement d'état du alt de l'image du mainMenuBtn
            var replaceAltmainMenuBtn = mainMenuBtn.find('img').attr('alt').replace(l10n['close'], l10n['open']);
            mainMenuBtn.find('img').attr('alt', replaceAltmainMenuBtn);
            // Changement d'état du alt de l'image du closeResponsiveMenu
            var replaceAltmainMenuBtnOpen = closeResponsiveMenu.find('img').attr('alt').replace(l10n['close'], l10n['open']);
            closeResponsiveMenu.find('img').attr('alt', replaceAltmainMenuBtnOpen);
        }

        // State menu
        var stateMenu = true;

        // toggle menu
        menuActions.click(function () {
            if(stateMenu) {
                openResponsiveMenuFallback();
                stateMenu = false;
            }
            else {
                closeResponsiveMenuFallback();
                stateMenu = true;
            }
        });

        // close menu when clicking site overlay
        $('.responsiveMenuOverlay').click(function () {
            if(stateMenu) {
                openResponsiveMenuFallback();
                stateMenu = false;
            }
            else {
                closeResponsiveMenuFallback();
                stateMenu = true;
            }
        });

        $('> ul > li', mainMenu).each(function () {
            var obj = $(this),
                menuDropDown = $('> ul > li > ul', obj),
                state = false;

            if($('div.title', obj).length == 0) {
                $('> a', obj).wrap('<div class="title"></div>');
            }

            var titleDropDown = $('.title', obj);

            if(titleDropDown.next().length != 0) {
                titleDropDown.append('<button type="submit" class="resetButton buttonLvl1"></button>');
                obj.addClass('dropDownOn');
            }

            var buttonDropDown = $('.buttonLvl1', obj);

            function openSubLevel() {
                obj.addClass('display');
                menuDropDown.attr('aria-hidden', 'false');
                buttonDropDown.attr('aria-expanded', 'true');
                $('img', buttonDropDown).attr('alt', 'Cacher les rubriques');
            }

            function closeSubLevel() {
                obj.removeClass('display');
                menuDropDown.attr('aria-hidden', 'true');
                buttonDropDown.attr('aria-expanded', 'false');
                $('img', buttonDropDown).attr('alt', 'Afficher les rubriques');
            }

            buttonDropDown.attr('aria-haspopup', 'true');
            buttonDropDown.attr('aria-expanded', 'false');
            menuDropDown.attr('aria-hidden', 'true');

            buttonDropDown.on('click', function () {
                if(state === false) {
                    openSubLevel();
                    state = true;
                }
                else {
                    closeSubLevel();
                    state = false;
                }
            });

        });

        // $('> ul > li > ul > li', mainMenu).each(function () {
        //     var obj = $(this),
        //         menuDropDown = $('> ul > li > ul > li > ul', obj),
        //         state = false;

        //     if($('div.drop-title', obj).length == 0) {
        //         $('> a', obj).wrap('<div class="drop-title"></div>');
        //     }

        //     var titleDropDown = $('.drop-title', obj);

        //     if(titleDropDown.next().length != 0) {
        //         titleDropDown.append('<button type="submit" class="resetButton buttonLvl2"></button>');
        //         obj.addClass('dropDownOn');
        //     }

        //     var buttonDropDown = $('.buttonLvl2', obj);

        //     function openSubLevel() {
        //         obj.addClass('display');
        //         menuDropDown.attr('aria-hidden', 'false');
        //         buttonDropDown.attr('aria-expanded', 'true');
        //         $('img', buttonDropDown).attr('alt', 'Cacher les rubriques');
        //     }

        //     function closeSubLevel() {
        //         obj.removeClass('display');
        //         menuDropDown.attr('aria-hidden', 'true');
        //         buttonDropDown.attr('aria-expanded', 'false');
        //         $('img', buttonDropDown).attr('alt', 'Afficher les rubriques');
        //     }

        //     buttonDropDown.attr('aria-haspopup', 'true');
        //     buttonDropDown.attr('aria-expanded', 'false');
        //     menuDropDown.attr('aria-hidden', 'true');

        //     buttonDropDown.on('click', function () {
        //         if(state === false) {
        //             openSubLevel();
        //             state = true;
        //         }
        //         else {
        //             closeSubLevel();
        //             state = false;
        //         }
        //     });
        // });

        $('> ul > li > ul > li > ul > li', mainMenu).each(function () {
            var obj = $(this),
                menuDropDown = $('> ul > li > ul > li > ul > li > ul', obj),
                state = false;

            if($('div.drop-title-sublevel', obj).length == 0) {
                $('> a', obj).wrap('<div class="drop-title-sublevel"></div>');
            }

            var titleDropDown = $('.drop-title-sublevel', obj);

            if(titleDropDown.next().length != 0) {
                titleDropDown.append('<button type="submit" class="resetButton buttonLvl3"></button>');
                obj.addClass('dropDownOn');
            }

            var buttonDropDown = $('.buttonLvl3', obj);

            function openSubLevel() {
                obj.addClass('display');
                menuDropDown.attr('aria-hidden', 'false');
                buttonDropDown.attr('aria-expanded', 'true');
                $('img', buttonDropDown).attr('alt', 'Cacher les rubriques');
            }

            function closeSubLevel() {
                obj.removeClass('display');
                menuDropDown.attr('aria-hidden', 'true');
                buttonDropDown.attr('aria-expanded', 'false');
                $('img', buttonDropDown).attr('alt', 'Afficher les rubriques');
            }

            buttonDropDown.attr('aria-haspopup', 'true');
            buttonDropDown.attr('aria-expanded', 'false');
            menuDropDown.attr('aria-hidden', 'true');

            buttonDropDown.on('click', function () {
                if(state === false) {
                    openSubLevel();
                    state = true;
                }
                else {
                    closeSubLevel();
                    state = false;
                }
            });
        });
    }
}

responsiveMenu.inited = false;