$(document).ready(function () {
    var mediaSmartphone = 'mediaSmartphone',
        mediaTablet = 'mediaTablet',
        mediaDesktop = 'mediaDesktop';

    var carouselAlt = carrousel3({
        container:       '.scenes-gallery',
        mediaDesktop:    {
            carouselVisible: 7,
            allowWrap:       true
        },
        mediaTablet:     {
            carouselVisible: 4,
            allowWrap:       true
        },
        mediaSmartphone: {
            carouselVisible: 1,
            allowWrap:       true
        }
    });

    var carouselStage = carrousel3({
        container:       '.stage-gallery',
        mediaDesktop:    'destroy',
        mediaTablet:     {
            carouselVisible: 1,
            allowWrap:       true
        },
        mediaSmartphone: {
            carouselVisible: 1,
            allowWrap:       true
        }
    });

    var carouselPartners = carrousel3({
        container:       '.partners-gallery',
        mediaDesktop:    {
            carouselVisible: 4,
            allowWrap:       true
        },
        mediaTablet:     {
            carouselVisible: 2,
            allowWrap:       true
        },
        mediaSmartphone: {
            carouselVisible: 1,
            allowWrap:       true
        }
    });

    var jRes = jRespond([
        {
            label: 'smartphone',
            enter: 0,
            exit:  767
        }, {
            label: 'tablet',
            enter: 768,
            exit:  1279
        }, {
            label: 'desktop',
            enter: 1280,
            exit:  10000
        }
    ]);

    jRes.addFunc([
        {
            breakpoint: 'smartphone',
            enter:      function () {
                myInitFuncSmartphone();

            },
            exit:       function () {
            }
        },
        {
            breakpoint: 'tablet',
            enter:      function () {
                myInitFuncTablet();

            },
            exit:       function () {
            }
        },
        {
            breakpoint: 'desktop',
            enter:      function () {
                myInitFuncDesktop();

            },
            exit:       function () {
            }
        }
    ]);

    function myInitFuncSmartphone() {
        //menuStratis(mediaSmartphone); // 190_stratis_menu.js
        fancyboxGalerie(mediaSmartphone); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaSmartphone); // 240_ stratis_galerie_classique.js
        searchState(mediaSmartphone); // 290_stratis_search.js
        filtersState(mediaSmartphone); // 300_stratis_filters.js
        responsiveMenu(mediaSmartphone);
        carouselAlt(mediaSmartphone);
        carouselStage(mediaSmartphone);
        carouselPartners(mediaSmartphone);
        carrousel1State(mediaSmartphone); // 330_stratis_carrousel1.js
        //carrousel2State(mediaSmartphone); // 340_stratis_carrousel2.js
        afficheState(mediaSmartphone);
    }

    function myInitFuncTablet() {
        //menuStratis(mediaTablet); // 190_stratis_menu.js
        fancyboxGalerie(mediaTablet); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaTablet); // 240_ stratis_galerie_classique.js
        searchState(mediaTablet); // 290_stratis_search.js
        filtersState(mediaTablet); // 300_stratis_filters.js
        responsiveMenu(mediaTablet);
        carouselAlt(mediaTablet);
        carouselStage(mediaTablet);
        carouselPartners(mediaTablet);
        carrousel1State(mediaTablet); // 330_stratis_carrousel1.js
        //carrousel2State(mediaTablet); // 340_stratis_carrousel2.js
        afficheState(mediaTablet);
    }

    function myInitFuncDesktop() {
        //menuStratis(mediaDesktop); // 190_stratis_menu.js
        fancyboxGalerie(mediaDesktop); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaDesktop); // 240_stratis_galerie_classique.js
        searchState(mediaDesktop); // 290_stratis_search.js
        filtersState(mediaDesktop); // 300_stratis_filters.js
        responsiveMenu(mediaDesktop);
        carouselAlt(mediaDesktop);
        carouselStage(mediaDesktop);
        carouselPartners(mediaDesktop);
        carrousel1State(mediaDesktop); // 330_stratis_carrousel1.js
        //carrousel2State(mediaDesktop); // 340_stratis_carrousel2.js
        afficheState(mediaDesktop);
    }

    objectFitImages();

    // Generic functions
    preloadImages(); // 170_stratis_preload_images.js
    tooltipAccess(); // 200_stratis_tooltip.js
    tooltipDfn(); // 200_stratis_tooltip.js
    geocode(); // 205_stratis_geocode.js
    linkExternalNewWindow(); // 210_stratis_external_window.js
    fancyboxClassic(); // 220_stratis_fancybox_classic.js
    fancyboxAutoHome();  // 235_stratis_fancybox_auto_home.js
    sharePage(); // 250_stratis_share_page.js
    eventDdm(); // 260_stratis_event-ddm.js
    contentDdm(); // 270_stratis_content-ddm.js
    manageVideo(); // 280_stratis_video.js
    enableDatePicker(); // 310_stratis_datetimepicker.js
    enableTimePicker(); // 310_stratis_datetimepicker.js
    formValidation(); // 320_stratis_form_validation.js
    infiniteScroll(); // 350_stratis_infinite_scroll.js
    showTotal(); // 350_stratis_infinite_scroll.js
    catmenu(); // 355_stratis_catmenu.js
    cookiesManagement(); // 360_stratis_cookies_management.js
    faqDdm(); // 370_stratis_faq.js
    addContentInTitle(); // 380_stratis_add_in_title.js
    generatePie(); // 390_stratis_piechart.js
    backToTop();


    (function () {
        var
            $elem = $('.flash-info'),
            cookie = getCookie('flashinfo');

        if (!cookie) {
            $elem.show();
        }

        $elem.find('.flash-info-close').on('click', onClick);

        function onClick() {
            $elem.hide();
            $(window).resize();

            setCookie('flashinfo', true, {
                expires: 60 * 60 * 24 // week in seconds
            });
        }
    })();
});

function setCookie(name, value, options) {
    options = options || {};

    var expires = options.expires;

    if (typeof expires === "number") {
        var d = new Date();

        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }

    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    var updatedCookie = name + "=" + value;

    for (var propName in options) {
        updatedCookie += "; " + propName;

        var propValue = options[propName];

        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    document.cookie = updatedCookie;
}

function getCookie(name) {
    var matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function deleteCookie(name) {
    setCookie(name, "", {
        expires: -1
    })
}

accessTooltipState(); // 400_stratis_access-tooltip.js

$(window).on('load', function () {
    var
        $list = $('.social-list'),
        $filterBtns = $('.filter-social [data-filter]');

    $list.isotope({
        itemSelector: 'li',
        masonry:      {}
    });

    filter('.social-list-all');
    toggleActive($filterBtns.eq(0));

    $('.filter-social').on('click', 'a', function (e) {
        e.preventDefault();

        var $elem = $(this);

        filter($elem.attr('data-filter'));
        toggleActive($elem);
    });

    function filter(val) {
        $list.isotope({
            filter: val
        });
    }

    function toggleActive($elem) {
        deactivateAll();
        makeActive($elem);
    }

    function makeActive($elem) {
        $elem.addClass('active');
    }

    function deactivateAll() {
        $filterBtns.removeClass('active');
    }

    // Homepage affiche-section_menu open-close
    /*
    $(".ddm__home_affiche .ddm__title button").click(function () {
        $(".ddm__home_affiche").toggleClass("ddm--active");
    });
    */

});

$('.block-decor-content').mouseenter(function () {
    $('.block-decor-link').addClass('link-hover')
});
$('.block-decor-link').mouseleave(function () {
    $('.block-decor-link').removeClass('link-hover')
});


function backToTop() {
    $(window).scroll(function () {
        if ($(this).scrollTop()) {
            $('.go-to-top').fadeIn();
        } else {
            $('.go-to-top').fadeOut();
        }

        if ($(window).scrollTop() + $(window).height() < $(document).height() - $(".footer").height()) {
            $('.go-to-top').css("position", "fixed"); //resetting it
            $('.go-to-top').css("bottom", "1rem"); //resetting it
        }
        if ($(window).scrollTop() + $(window).height() > $(document).height() - $(".footer").outerHeight()) {
            $('.go-to-top').css("position", "relative"); // make it related
            $('.go-to-top').css("bottom", '1rem'); // 60 px, height of .backToTop
        }
    });
};

