// // Menu principal
// ;(function () {
// 	var $nav = $('.main-menu'),
// 		$firstLevel = $nav.find('> ul'),
// 		$items = $firstLevel.find('> li');
//
// 	$items.filter(':has(.menu)').addClass('has-drop');
//
// 	$firstLevel.find('> li > a')
// 	.on('click', function (e) {
// 		e.preventDefault();
//
// 		var $this = $(this.parentNode);
//
// 		if($this.hasClass('hover')) {
// 			$this.removeClass('hover focus');
// 		}
// 		else {
// 			$this.siblings().removeClass('hover');
// 			$this.addClass('hover');
// 		}
// 	});
//
// 	$firstLevel.find('> li > a')
// 	.on('focusin', function () {
// 		$(this.parentNode).siblings().find('.focus').removeClass('focus');
// 	});
//
// 	$firstLevel.find('li > a')
// 	.on('focusin', function () {
// 		$(this.parentNode).addClass('focus').siblings().removeClass('focus');
// 	});
//
// 	$(document).on('focusin click', function (e) {
// 		if(!$(e.target).closest($nav).length) {
// 			$nav.find('.focus, .hover').removeClass('focus hover');
// 		}
// 	});
//
// 	$('.main-menu-close').on('click', function (e) {
// 		e.preventDefault();
// 		e.stopPropagation();
// 		$(this).parents('.has-drop').removeClass('focus').removeClass('hover');
// 	});
// })();


// Menu principal
;(function () {
    var $nav = $('.main-menu'),
        $firstLevel = $nav.find('> ul'),
        $items = $firstLevel.find('> li');

    function recalcHeightSublevelContainer(elem) {
        var $this = $(elem);
        var $heightSublevelContainer = $this.parent().find('> ul > li').outerHeight();
        var $heightItemsThirdSublevel;

        $this.parent().find('> ul > li > ul > li > a').on('mouseover', function(e) {
            var $heightInnerList = $(this).parent().find('> ul').outerHeight(),
                $wrapper = $(this).parent();

            if(!$wrapper.siblings().hasClass('focus') && $(e.target).closest($wrapper).length > 0) {
                if ($heightInnerList > $heightSublevelContainer) {
                    $(this).parent().parent().parent().css({"height": $heightInnerList});
                } else {
                    $(this).parent().parent().parent().css({"height": "auto"});
                }
            }
        });

        $this.parent().find('> ul > li > ul > li > a').on('click focus', function(e) {
            var $heightInnerList = $(this).parent().find('> ul').outerHeight(),
                $wrapper = $(this).parent();

            if ($heightInnerList > $heightSublevelContainer) {
                $(this).parent().parent().parent().css({"height": $heightInnerList});
            } else {
                $(this).parent().parent().parent().css({"height": "auto"});
            }
        });

        $this.parent().find('> ul > li > ul').on('mouseleave', function(e) {
            if(!$(this).children().hasClass('focus')) {
                $(this).parent().css({"height": "auto"});
            }
        });
    }

    function setHeightThirdSublevel(elem) {
        var $this = $(elem);
        var $heightSublevelContainer = $this.parent().find('> ul > li').outerHeight();

        $this.parent().find('> ul > li > ul> li > ul').css({ 'min-height' : $heightSublevelContainer });
    }

    $items.filter(':has(.menu)').addClass('has-drop');

    $firstLevel.find('> li > a')
        .on('click', function () {
            var $this = $(this.parentNode);

            $this.siblings().find('ul > li').removeClass('focus not-focus');
            $this.find('ul > li').removeClass('focus not-focus');
            $this.siblings().find('> ul > li').css({'height':'auto'});
            $this.find('> ul > li').css({'height':'auto'});

            setHeightThirdSublevel(this);

            recalcHeightSublevelContainer(this);

            if($this.hasClass('hover')) {
                $this.removeClass('hover focus');
            }
            else {
                $this.siblings().removeClass('focus hover');
                $this.addClass('hover');
            }
        });

    $firstLevel.find('> li > a')
        .on('focusin', function () {
            var $this = $(this.parentNode);

            if(!$this.hasClass('focus')) {
                $this.siblings().removeClass('focus hover');
                $this.addClass('focus');

                $this.siblings().find('ul > li').removeClass('focus not-focus');
                $this.find('ul > li').removeClass('focus not-focus');
                $this.siblings().find('> ul > li').css({'height':'auto'});
                $this.find('> ul > li').css({'height':'auto'});

                setHeightThirdSublevel(this);

                recalcHeightSublevelContainer(this);
            }
        });

    $firstLevel.find('li > ul > li > a')
        .on('focusin', function () {
            $(this.parentNode).addClass('focus').siblings().removeClass('focus').addClass('not-focus');
        });

    $(document).on('focusin click', function (e) {
        if(!$(e.target).closest($nav).length) {
            $nav.find('.focus, .hover, .not-focus').removeClass('focus hover not-focus');
            $nav.find('> ul > li > ul > li').css({'height':'auto'});
        }
    });

    $('.main-menu-close').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).parents('.has-drop').removeClass('focus hover not-focus');
        $(this).parent().css({'height':'auto'});
    });
})();
