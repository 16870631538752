function linkExternalNewWindow() {
    $('.rte a').not('body.home a').each(function () {
        if ($(this).find('.external').length === 0) {
            var $this = $(this),
                isExternal = $this.filter(function () {
                    return this.hostname && this.hostname !== location.hostname;
                }).length,
                fileTypes = ['.pdf', '.doc', '.docx', '.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.zip', '.odt', '.rtf'],
                isFile = $this.filter(function () {
                    return (new RegExp(fileTypes.join('|') + '$', 'gi')).test(this.href);
                }).length,
                titleText = null;

            if (isExternal > 0 || isFile > 0) {
                // target attribute
                if (!$this.attr('target')) {
                    $this.attr('target', '_blank');
                }
                // title attribute
                if (!$this.attr('title')) {
                    titleText = $this.find('.title').text() || $this.text() || $this.find('img').attr('alt');
                }
            }
            if (isExternal > 0) {
                // class
                if ($this.find('img').length === 0 && $this.find('.title').length === 0) {
                    $this.wrapInner('<span class="external"></span>');
                }
                // title attribute
                if (titleText) {
                    if (titleText.trim().length > 0) {
                        titleText = titleText.trim() + ' (' + l10n['linkExternalLink'] + ', ' + l10n['linkNewWindow'] + ')';
                    } else {
                        titleText = titleText.trim() + l10n['linkExternalLink'] + ', ' + l10n['linkNewWindow'];
                    }
                }
            }
            if (isFile > 0) {
                // title attribute
                if (titleText) {
                    if (titleText.trim().length > 0) {
                        titleText = titleText.trim() + ' (' + l10n['linkNewWindow'] + ')';
                    } else {
                        titleText = titleText.trim() + l10n['linkNewWindow'];
                    }
                }
            }
            if ((isExternal > 0 || isFile > 0) && !$this.attr('title')) {
                $this.attr('title', titleText);
            }
        }
    });
}
