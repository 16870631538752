function enableDatePicker() {
    // Input date is supported ?
    var hasNativeDatePicker = function () {
        var elem = document.createElement('input');

        elem.setAttribute('type', 'date');
        elem.value = 'foo';

        return (elem.type == 'date' && elem.value != 'foo');
    };

    // If has no native datepicker (Firefox)
    if (!hasNativeDatePicker()) {
        function frenchToEnglishDate(frenchDate) {
            var dateElem = frenchDate.split('-');
            return dateElem[1] + '/' + dateElem[2] + '/' + dateElem[0];
        }

        function showInternationalDate(argObj) {
            var argObjId = argObj.id || argObj.attr('id'),
                argObjDate = argObj.date || new Date(frenchToEnglishDate(argObj.val())),
                inputHidden = document.getElementById(argObjId + '-hidden');

            inputHidden.value = datePickerController.printFormattedDate(argObjDate, "%Y-%m-%d", false);
        }

        $('input[type="date"]').each(function () {
            var thisId = $(this).attr('id'),
                thisName = $(this).attr('name'),
                obj = {};

            $(this)
                .removeAttr('name')
                .after('<input type="hidden" name="' + thisName + '" id="' + thisId + '-hidden" value="">');

            if ($(this).val() !== '') {
                showInternationalDate($(this));
            }

            obj[thisId] = "%d-%m-%Y";
            datePickerController.createDatePicker({
                formElements:      obj,
                callbackFunctions: {
                    "datereturned": [showInternationalDate]
                }
            });
        });
    }
    // If has no native datepicker (Chrome, Opera, Safari)
    else {
        $('input[type="date"]').each(function () {
            var thisId = $(this).attr('id'),
                thisValue = $(this).val() || '',
                thisName = $(this).attr('name');

            $(this)
                .removeAttr('name')
                .after('<input type="hidden" name="' + thisName + '" id="' + thisId + '-hidden" value="' + thisValue + '">')
                .on("change", function () {
                    var inputHidden = $(this).next();
                    inputHidden.val($(this).val());
                });
        });
    }
}

// Specific for chrome who not use datepicker plugin.


function enableTimePicker() {
    $('.time input').timepicker({
        'timeFormat': 'H:i'
    });
}

