function checkSocialCookieEnabled() {
    var socialCookieEnabled = false;
    if (typeof localStorage.getItem('cookiesFunctional') != "undefined" && localStorage.getItem('cookiesFunctional') != null && localStorage.getItem('cookiesFunctional') == 1) {
        socialCookieEnabled = true;
    }
    return socialCookieEnabled;
}
// Example of use
/*if(checkSocialCookieEnabled()) {
 alert('Social cookie is enabled');
 }*/

function clearCookie(name, domain, path) {
    try {
        function Get_Cookie(check_name) {
            // first we'll split this cookie up into name/value pairs
            // note: document.cookie only returns name=value, not the other components
            var a_all_cookies = document.cookie.split(';'),
                a_temp_cookie = '',
                cookie_name = '',
                cookie_value = '',
                b_cookie_found = false;

            for (i = 0; i < a_all_cookies.length; i++) {
                // now we'll split apart each name=value pair
                a_temp_cookie = a_all_cookies[i].split('=');

                // and trim left/right whitespace while we're at it
                cookie_name = a_temp_cookie[0].replace(/^\s+|\s+$/g, '');

                // if the extracted name matches passed check_name
                if (cookie_name == check_name) {
                    b_cookie_found = true;
                    // we need to handle case where cookie has no value but exists (no = sign, that is):
                    if (a_temp_cookie.length > 1) {
                        cookie_value = unescape(a_temp_cookie[1].replace(/^\s+|\s+$/g, ''));
                    }
                    // note that in cases where cookie is initialized but no value, null is returned
                    return cookie_value;
                    break;
                }
                a_temp_cookie = null;
                cookie_name = '';
            }
            if (!b_cookie_found) {
                return null;
            }
        }

        if (Get_Cookie(name)) {
            var domain = domain || document.domain;
            var path = path || "/";
            document.cookie = name + "=; expires=" + new Date + "; domain=" + domain + "; path=" + path;
        }
    }
    catch (err) {
    }
}

function removeFunctionalCookies() {
    var domainToUseForGoogle = location.hostname.replace('www', '');

    // dev
    clearCookie('__utma', domainToUseForGoogle, '/');
    clearCookie('__utmb', domainToUseForGoogle, '/');
    clearCookie('__utmc', domainToUseForGoogle, '/');
    clearCookie('__utmz', domainToUseForGoogle, '/');
    clearCookie('__utmv', domainToUseForGoogle, '/');
    clearCookie('comments_title', location.hostname, '/');
    clearCookie('comments_firstname', location.hostname, '/');
    clearCookie('comments_lastname', location.hostname, '/');
    clearCookie('comments_email', location.hostname, '/');
    clearCookie('comments_homepage', location.hostname, '/');
    clearCookie('tx_comments_pi1_title', location.hostname, '/');
    clearCookie('tx_comments_pi1_firstname', location.hostname, '/');
    clearCookie('tx_comments_pi1_lastname', location.hostname, '/');
    clearCookie('tx_comments_pi1_email', location.hostname, '/');
    clearCookie('tx_comments_pi1_homepage', location.hostname, '/');
}

// cookies management
/**
 * Les éléments à ne pas afficher si les cookies sociaux sont désactivés
 * doivent être wrappés comme ceci
 *
 * <div class="element-with-social-cookies">
 *    <!--
 *      example...
 *    -->
 * </div>
 */
function cookiesManagement() {
    // affiche/cache les éléments de contenu type réseaux sociaux
    if (checkSocialCookieEnabled()) {
        $('.element-with-social-cookies').each(function (index, value) {
            var content = $(this).html().replace('<!--', '').replace('-->', '');
            $(this).html(content);
        });
    }

    // gestion des cookies
    if ($('#cookie-manager').length > 0 || $('#cookies-top-bloc').length > 0) {
        // creation des localstorage avec la valeur 1 s'ils n'existent pas, ou s'ils ont été autorisés par l'utilisateur

        // cookies de suivi
        if (typeof localStorage.getItem('cookiesFunctional') == "undefined" || localStorage.getItem('cookiesFunctional') == null) {
            $('input#cookies-functional').attr('checked', 'checked');
            localStorage.setItem('cookiesFunctional', 1);
            cookiesManagement();
        } else {
            if (localStorage.getItem('cookiesFunctional') == 1) {
                $('input#cookies-functional').attr('checked', 'checked');
            } else {
                $('input#cookies-functional').removeAttr('checked');
                removeFunctionalCookies();
            }
        }

        $('#cookie-manager .cookies-top-bloc__submit, #cookies-top-bloc .cookies-top-bloc__submit').click(function () {
            $("#cookies-top-bloc").hide();
            // Save to local storage state of functional cookies
            var cookiesFunctionalCheckboxState = $('#cookies-functional').attr('checked');
            if (cookiesFunctionalCheckboxState) {
                localStorage.setItem('cookiesFunctional', 1);
            } else {
                localStorage.setItem('cookiesFunctional', 0);
                removeFunctionalCookies();
            }
            // save decision to localstorage
            localStorage.setItem('cookiesConfirmed', 1);
            $("#cookies-top-bloc").slideUp();
            $('.page').removeAttr('style');
            return false;
        });

    }

    // show cookies top block
    if (typeof localStorage.getItem('cookiesConfirmed') == "undefined" || localStorage.getItem('cookiesConfirmed') == null || localStorage.getItem('cookiesConfirmed') == 0) {
        $("#cookies-top-bloc").show();
    }
    if (window.innerWidth < 940 && $('#cookies-top-bloc').is(':visible')) {
        $('.page').css('padding-bottom', $('#cookies-top-bloc').outerHeight());
        $('.page').addClass('cookies-top-bloc--on');
    }
    $(window).resize(function () {
        if (window.innerWidth < 940 && $('#cookies-top-bloc').is(':visible')) {
            $('.page').css('padding-bottom', $('#cookies-top-bloc').outerHeight());
        } else {
            $('.page').removeClass('cookies-top-bloc--on');
            $('.page').removeAttr('style');
        }
    });
}
